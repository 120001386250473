import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrganizationModal = ({ task, onClose }) => {
    const [incomeRows, setIncomeRows] = useState([{ id: Date.now(), tcpInput: '', price: '', izmer: '', quantity: 1, percent: 100, sum: '', note: '', tcpSuggestions: [], counterpartyInput: '', counterpartySuggestions: [] }]);
    const [expenseRows, setExpenseRows] = useState([{ id: Date.now(), counterpartyInput: '', article: 'Субподряд', tcpSubInput: '', name: '', price: '', quantity: '1', percent: '100', sumExecutor: '', sumExpenses: '', note: '', counterpartySuggestions: [], tcpSubSuggestions: [] }]);
    const [tcpSuggestions, setTcpSuggestions] = useState([]);
    const [tcpSubSuggestions, setTcpSubSuggestions] = useState([]);
    const [agents, setAgents] = useState([]); // Состояние для контрагентов
    // KPI/Оплата по табелю
    const [kpiRows, setKpiRows] = useState([]);

    // Амортизация и ГСМ
    const [amortizationRows, setAmortizationRows] = useState([]);

    // Списание ТМЦ
    const [writeOffRows, setWriteOffRows] = useState([]);

    useEffect(() => {
        const fetchTcp = async () => {
            try {
                const response = await axios.get('/api/tcp?articlesId=1');
                setTcpSuggestions(response.data.tcp);
            } catch (error) {
                console.error('Error fetching TCP:', error);
            }
        };

        const fetchTcpSub = async () => {
            try {
                const response = await axios.get('/api/tcp?articlesId=2');
                console.log(response.data.tcp);
                setTcpSubSuggestions(response.data.tcp);
            } catch (error) {
                console.error('Error fetching TCP:', error);
            }
        };

        const fetchAgents = async () => {
            try {
                const response = await axios.get('/api/agent');
                console.log('Полученные контрагенты:', response.data);
                setAgents(response.data.agents);
            } catch (error) {
                console.error('Ошибка получения контрагентов:', error);
            }
        };

        fetchTcpSub();
        fetchAgents();
        fetchTcp();
    }, []);

    const handleTcpInputChange = (id, value) => {
        setIncomeRows(incomeRows.map(row => row.id === id ? { ...row, tcpInput: value, tcpSuggestions: filteredSuggestions(value) } : row));
    };

    const handleTcpSubInputChange = (id, value) => {
        setExpenseRows(expenseRows.map(row => row.id === id ? { ...row, tcpSubInput: value, tcpSubSuggestions: filteredSubSuggestions(value) } : row));
    };

    const handleCounterpartyInputChange = (id, value) => {
        setExpenseRows(expenseRows.map(row => row.id === id ? { ...row, counterpartyInput: value, counterpartySuggestions: filteredCounterpartySuggestions(value) } : row));
    };

    const handleNameInputChange = (id, value) => {
        setExpenseRows(expenseRows.map(row => row.id === id ? { ...row, name: value, tcpSubSuggestions: filteredSubSuggestions(value) } : row));
    };

    const filteredSuggestions = (input) => {
        return tcpSuggestions
            .filter(suggestion =>
                suggestion.name.toLowerCase().includes(input.toLowerCase())
            )
            .slice(0, 10);
    };

    const filteredSubSuggestions = (input) => {
        return tcpSubSuggestions
            .filter(suggestion =>
                suggestion.name.toLowerCase().includes(input.toLowerCase())
            )
            .slice(0, 10);
    };

    const filteredCounterpartySuggestions = (input) => {
        return agents
            .filter(agent =>
                agent.name.toLowerCase().includes(input.toLowerCase())
            )
            .slice(0, 10); // Ограничиваем до 10 подсказок
    };

    const handleSuggestionSelect = (id, suggestion) => {
        setIncomeRows(incomeRows.map(row =>
            row.id === id
                ? { ...row, tcpInput: suggestion.name, price: suggestion.price, izmer: suggestion.izmer, quantity: 1, percent: 100, sum: calculateSum(suggestion.price, 1, 100), tcpSuggestions: [] }
                : row
        ));
    };

    const handleNameSuggestionSelect = (id, suggestion) => {
        setExpenseRows(expenseRows.map(row =>
            row.id === id
                ? { ...row, name: suggestion.name, price: suggestion.price, tcpSubSuggestions: [], sumExpenses: calculateSum(suggestion.price, row.quantity, row.percent) }
                : row
        ));
    };

    const handleCounterpartySuggestionSelect = (id, suggestion) => {
        setExpenseRows(expenseRows.map(row =>
            row.id === id
                ? { ...row, counterpartyInput: suggestion.name, article: 'Субподряд', counterpartySuggestions: [] }
                : row
        ));
    };

    const calculateSum = (price, quantity, percent) => {
        const parsedPrice = parseFloat(price.replace(/\s/g, '').replace(',', '.'));
        const parsedQuantity = parseFloat(quantity);
        const parsedPercent = parseFloat(percent);
        return ((parsedPrice * parsedQuantity * (parsedPercent / 100)).toFixed(2));
    };

    const handleQuantityChange = (id, value) => {
        setIncomeRows(incomeRows.map(row => row.id === id ? { ...row, quantity: value, sum: calculateSum(row.price, value, row.percent) } : row));
    };

    const handlePercentChange = (id, value) => {
        setIncomeRows(incomeRows.map(row => row.id === id ? { ...row, percent: value, sum: calculateSum(row.price, row.quantity, value) } : row));
    };

    const handleNoteChange = (id, value) => {
        setIncomeRows(incomeRows.map(row => row.id === id ? { ...row, note: value } : row));
    };

    const addIncomeRow = () => {
        setIncomeRows([...incomeRows, { id: Date.now(), tcpInput: '', price: '', izmer: '', quantity: 1, percent: 100, sum: '', note: '', tcpSuggestions: [], counterpartyInput: '', counterpartySuggestions: [] }]);
    };

    const addExpenseRow = () => {
        setExpenseRows([...expenseRows, { id: Date.now(), counterpartyInput: '', article: 'Субподряд', tcpSubInput: '', name: '', price: '', quantity: '', percent: '', sumExecutor: '', sumExpenses: '', note: '', counterpartySuggestions: [], tcpSubSuggestions: [] }]);
    };

    const addKpiRow = () => {
        setKpiRows([...kpiRows, { id: Date.now(), counterpartyInput: '', name: '', price: '', quantity: '', sum: '', note: '', counterpartySuggestions: [] }]);
    };

    const addAmortizationRow = () => {
        setAmortizationRows([...amortizationRows, { id: Date.now(), counterpartyInput: '', name: '', price: '', quantity: '', sum: '', note: '', counterpartySuggestions: [] }]);
    };

    const addWriteOffRow = () => {
        setWriteOffRows([...writeOffRows, { id: Date.now(), counterpartyInput: '', name: '', price: '', quantity: '', sum: '', note: '', counterpartySuggestions: [] }]);
    };

    const handleRemoveIncomeRow = (id) => {
        if (incomeRows.length > 1) {
            setIncomeRows(incomeRows.filter(row => row.id !== id));
        } else {
            alert("Нельзя удалить последнюю строку.");
        }
    };

    const handleRemoveExpenseRow = (id) => {
        if (expenseRows.length > 1) {
            setExpenseRows(expenseRows.filter(row => row.id !== id));
        } else {
            alert("Нельзя удалить последнюю строку.");
        }
    };

    return (
        <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Доходы и расходы для задания {task.id}</h1>
                        <button className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>{task.id}</b></td>
                                        <td>{task.service_object_title}</td>
                                        <td>{task.news_type_name}</td>
                                        <td><b>{task.title}</b></td>
                                        <td>{task.text}</td>
                                        <td>{task.department_title}</td>
                                        <td>{task.status_name}</td>
                                        <td>{task.assigned_user_fio}</td>
                                        <td>{task.expired_date}</td>
                                        <td>{task.service_object_layer_title}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h6>Раздел 1. Доходы</h6>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Наименование</th>
                                        <th>Ед. измерения</th>
                                        <th>Цена</th>
                                        <th>Количество</th>
                                        <th>Процент</th>
                                        <th>Сумма</th>
                                        <th>Заметка</th>
                                        <th>Действия</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {incomeRows.map(row => (
                                        <tr key={row.id}>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.tcpInput}
                                                    onChange={(e) => handleTcpInputChange(row.id, e.target.value)}
                                                    onFocus={() => setIncomeRows(incomeRows.map(r =>
                                                        r.id === row.id ? { ...r, tcpSuggestions: filteredSuggestions(row.tcpInput) } : r
                                                    ))}
                                                />
                                                {row.tcpSuggestions.length > 0 && (
                                                    <ul className="list-group position-absolute z-index-1">
                                                        {row.tcpSuggestions.map(suggestion => (
                                                            <li
                                                                key={suggestion.id}
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => handleSuggestionSelect(row.id, suggestion)}
                                                            >
                                                                {suggestion.name} (Цена: {suggestion.price} {suggestion.izmer})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </td>
                                            <td>{row.izmer}</td>
                                            <td>{row.price}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.quantity}
                                                    onChange={(e) => handleQuantityChange(row.id, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.percent}
                                                    onChange={(e) => handlePercentChange(row.id, e.target.value)}
                                                />
                                            </td>
                                            <td>{row.sum}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.note}
                                                    onChange={(e) => handleNoteChange(row.id, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-danger" onClick={() => handleRemoveIncomeRow(row.id)}>Удалить</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-primary" onClick={addIncomeRow}>Добавить доход</button>
                            <br/><br/>
                            <h6>Раздел 2. Расходы</h6>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Контрагент</th>
                                        <th>Статья</th>
                                        <th>Наименование</th>
                                        <th>Цена</th>
                                        <th>Количество</th>
                                        <th>Процент</th>
                                        <th>Сумма</th>
                                        <th>Заметка</th>
                                        <th>Действия</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseRows.map(row => (
                                        <tr key={row.id}>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.counterpartyInput}
                                                    onChange={(e) => handleCounterpartyInputChange(row.id, e.target.value)}
                                                    onFocus={() => setExpenseRows(expenseRows.map(r =>
                                                        r.id === row.id ? { ...r, counterpartySuggestions: filteredCounterpartySuggestions(row.counterpartyInput) } : r
                                                    ))}
                                                />
                                                {row.counterpartySuggestions.length > 0 && (
                                                    <ul className="list-group position-absolute z-index-1">
                                                        {row.counterpartySuggestions.map(suggestion => (
                                                            <li
                                                                key={suggestion.id}
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => handleCounterpartySuggestionSelect(row.id, suggestion)}
                                                            >
                                                                {suggestion.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </td>
                                            <td>{row.article}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.name}
                                                    onChange={(e) => handleNameInputChange(row.id, e.target.value)}
                                                    onFocus={() => setExpenseRows(expenseRows.map(r =>
                                                        r.id === row.id ? { ...r, tcpSubSuggestions: filteredSubSuggestions(row.name) } : r
                                                    ))}
                                                />
                                                {row.tcpSubSuggestions.length > 0 && (
                                                    <ul className="list-group position-absolute z-index-1">
                                                        {row.tcpSubSuggestions.map(suggestion => (
                                                            <li
                                                                key={suggestion.id}
                                                                className="list-group-item list-group-item-action"
                                                                onClick={() => handleNameSuggestionSelect(row.id, suggestion)}
                                                            >
                                                                {suggestion.name} (Цена: {suggestion.price} {suggestion.izmer})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </td>
                                            <td>{row.price}</td>
                                            <td>{row.quantity}</td>
                                            <td>{row.percent}</td>
                                            <td>{row.sumExecutor}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.note}
                                                    onChange={(e) => handleNoteChange(row.id, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-danger" onClick={() => handleRemoveExpenseRow(row.id)}>Удалить</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-primary" onClick={addExpenseRow}>Добавить расход</button>
                            <br/><br/>
                            <div className="large-modal-header">
                            <h6>Раздел 3. KPI/Оплата по табелю</h6>
                        </div>
                        <div className="modal-details">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Контрагент</th>
                                                <th>Статья</th>
                                                <th>Наименование позиции ТЦП</th>
                                                <th>Ед. изм</th>
                                                <th>Цена</th>
                                                <th>Кол-во</th>
                                                <th>Коэф. затрат</th>
                                                <th>Учитывать 1/0</th>
                                                <th>Сумма исполнителю</th>
                                                <th>Сумма расходов</th>
                                                <th>Примечание</th>
                                                <th>Действия</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {kpiRows.map(row => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.counterpartyInput}
                                                            onChange={(e) => handleCounterpartyInputChange(row.id, e.target.value, 'kpi')}
                                                            onFocus={() => setKpiRows(kpiRows.map(r =>
                                                                r.id === row.id ? { ...r, counterpartySuggestions: [] } : r
                                                            ))}
                                                        />
                                                        {row.counterpartySuggestions.length > 0 && (
                                                            <ul className="list-group position-absolute z-index-1">
                                                                {row.counterpartySuggestions.map(suggestion => (
                                                                    <li
                                                                        key={suggestion.id}
                                                                        className="list-group-item list-group-item-action"
                                                                        onClick={() => handleCounterpartySuggestionSelect(row.id, suggestion, 'kpi')}
                                                                    >
                                                                        {suggestion.name}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.name}
                                                            onChange={(e) => handleNameInputChange(row.id, e.target.value, 'kpi')}
                                                        />
                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => setKpiRows(kpiRows.filter(r => r.id !== row.id))}>Удалить</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={12}>
                                                    <button className="btn btn-primary" onClick={addKpiRow}>Добавить строку</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Раздел 4. Расходы - Амортизация и ГСМ */}
                        <div className="large-modal-header">
                            <h6>Раздел 4. Расходы - Амортизация и ГСМ</h6>
                        </div>
                        <div className="modal-details">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Контрагент</th>
                                                <th>Статья</th>
                                                <th>Наименование позиции ТЦП</th>
                                                <th>Наименование техники</th>
                                                <th>Ед. изм</th>
                                                <th>Цена</th>
                                                <th>Кол-во</th>
                                                <th>Сумма</th>
                                                <th>Примечание</th>
                                                <th>Действия</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {amortizationRows.map(row => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.counterpartyInput}
                                                            onChange={(e) => handleCounterpartyInputChange(row.id, e.target.value, 'amortization')}
                                                            onFocus={() => setAmortizationRows(amortizationRows.map(r =>
                                                                r.id === row.id ? { ...r, counterpartySuggestions: [] } : r
                                                            ))}
                                                        />
                                                        {row.counterpartySuggestions.length > 0 && (
                                                            <ul className="list-group position-absolute z-index-1">
                                                                {row.counterpartySuggestions.map(suggestion => (
                                                                    <li
                                                                        key={suggestion.id}
                                                                        className="list-group-item list-group-item-action"
                                                                        onClick={() => handleCounterpartySuggestionSelect(row.id, suggestion, 'amortization')}
                                                                    >
                                                                        {suggestion.name}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.name}
                                                            onChange={(e) => handleNameInputChange(row.id, e.target.value, 'amortization')}
                                                        />
                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => setAmortizationRows(amortizationRows.filter(r => r.id !== row.id))}>Удалить</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={10}>
                                                    <button className="btn btn-primary" onClick={addAmortizationRow}>Добавить строку</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Раздел 5. Расходы – Списание ТМЦ */}
                        <div className="large-modal-header">
                            <h6>Раздел 5. Расходы – Списание ТМЦ</h6>
                        </div>
                        <div className="modal-details">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Контрагент</th>
                                                <th>Наименование позиции ТЦП</th>
                                                <th>Ед. изм</th>
                                                <th>Цена</th>
                                                <th>Кол-во</th>
                                                <th>Сумма</th>
                                                <th>Примечание</th>
                                                <th>Действия</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {writeOffRows.map(row => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.counterpartyInput}
                                                            onChange={(e) => handleCounterpartyInputChange(row.id, e.target.value, 'writeOff')}
                                                            onFocus={() => setWriteOffRows(writeOffRows.map(r =>
                                                                r.id === row.id ? { ...r, counterpartySuggestions: [] } : r
                                                            ))}
                                                        />
                                                        {row.counterpartySuggestions.length > 0 && (
                                                            <ul className="list-group position-absolute z-index-1">
                                                                {row.counterpartySuggestions.map(suggestion => (
                                                                    <li
                                                                        key={suggestion.id}
                                                                        className="list-group-item list-group-item-action"
                                                                        onClick={() => handleCounterpartySuggestionSelect(row.id, suggestion, 'writeOff')}
                                                                    >
                                                                        {suggestion.name}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.name}
                                                            onChange={(e) => handleNameInputChange(row.id, e.target.value, 'writeOff')}
                                                        />
                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => setWriteOffRows(writeOffRows.filter(r => r.id !== row.id))}>Удалить</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={8}>
                                                    <button className="btn btn-primary" onClick={addWriteOffRow}>Добавить строку</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" onClick={onClose}>Закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationModal;
