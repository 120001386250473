import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OrganizationModal from './OrganizationModal';

const OrganizationsList = () => {
    const [tasks, setTasks] = useState([]); // Изменено на 'tasks'
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState('');
    const [selectedTask, setSelectedTask] = useState(null); // Изменено на 'selectedTask'

    useEffect(() => {
        fetchTasks(); // Изменено на fetchTasks
    }, []);

    const fetchTasks = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/organizations'); // Обновлено на правильный API
            console.log(response.data.news_list);
            setTasks(response.data.news_list); // Убедитесь, что это массив
            setError(null);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTaskById = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/tasks/${id}`); // Обновлено на правильный API
            setTasks([response.data.news_data]); // Предполагается, что ответ содержит одно задание
            setError(null);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchTaskById(taskId); // Обновлено на fetchTaskById
    };

    const handleTaskIdChange = (e) => {
        setTaskId(e.target.value);
    };

    const handleResetSearch = () => {
        setTaskId('');
        fetchTasks(); // Обновлено на fetchTasks
    };

    const openModal = (task) => { // Изменено на 'task'
        setSelectedTask(task); // Изменено на 'setSelectedTask'
    };

    const closeModal = () => {
        setSelectedTask(null);
    };

    return (
        <div className="container mt-4">
            <h1>Задания</h1>
            {loading && <p>Загрузка...</p>}
            {error && <p>Ошибка: {error.message}</p>}
            <form onSubmit={handleSearch}>
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="ID Задания"
                        value={taskId}
                        onChange={handleTaskIdChange}
                    />
                    <button className="btn btn-outline-secondary" type="submit">Поиск</button>
                    <button type="button" className="btn btn-outline-secondary" onClick={handleResetSearch}>Сброс</button>
                </div>
            </form>

            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Заголовок</th>
                        <th>Регион</th>
                        <th>Тип</th>
                        <th>Ответственный</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task) => ( // Изменено на 'tasks'
                        <tr key={task.id}>
                            <td>{task.id}</td>
                            <td>{task.title}</td>
                            <td>{task.assigned_department_name}</td>
                            <td>{task.news_type_name}</td>
                            <td>{task.assigned_user_fio}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => openModal(task)}>доходы и расходы</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedTask && ( // Изменено на 'selectedTask'
                <OrganizationModal task={selectedTask} onClose={closeModal} /> // Изменено на 'task'
            )}
        </div>
    );
};

export default OrganizationsList;
