import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/styles.css'; // Подключаем стили

const AgentEditor = () => {
  const [agents, setAgents] = useState([]);
  const [projects, setProjects] = useState([]); // Для списка проектов
  const [articles, setArticles] = useState([]); // Для списка статей расхода
  const [newAgent, setNewAgent] = useState({
    name: '',
    description: '',
    role: '',
    namemap: '',
    loginmap: '',
    login: '',
    codeproject: [],
    articlesids: [],
    typepay: '',
    kf: '',
    kf1: '',
    kf2: '',
    kf3: '',
    kf4: '',
  });
  const [editingAgent, setEditingAgent] = useState(null);

  useEffect(() => {
    fetchAgents();
    fetchProjects();
    fetchArticles();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axios.get('/api/agent');
      console.log('Полученные:', response.data);
      setAgents(response.data.agents);
    } catch (error) {
      console.error('Ошибка получения контрагентов:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get('/api/project'); 
      console.log('Полученные проекты:', response.data.project);
      setProjects(response.data.project); 
    } catch (error) {
      console.error('Ошибка получения проектов:', error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get('/api/articles'); // Запрос списка статей расхода
      setArticles(response.data.articles || []); // Ensure it's always an array
    } catch (error) {
      console.error('Ошибка получения статей расхода:', error);
    }
  };

  const addAgent = async () => {
    try {
      await axios.post('/api/agent', {
        ...newAgent,
        codeproject: newAgent.codeproject.join(','), // Преобразование массива в строку
        articlesids: newAgent.articlesids.join(','), // Преобразование массива в строку
      });
      setNewAgent({
        name: '',
        description: '',
        role: '',
        namemap: '',
        loginmap: '',
        login: '',
        codeproject: [],
        articlesids: [],
        typepay: '',
        kf: '',
        kf1: '',
        kf2: '',
        kf3: '',
        kf4: '',
      });
      fetchAgents(); 
    } catch (error) {
      console.error('Ошибка добавления контрагента:', error);
    }
  };

  const deleteAgent = async (id) => {
    try {
      await axios.delete(`/api/agent/${id}`);
      fetchAgents();
    } catch (error) {
      console.error('Ошибка удаления контрагента:', error);
    }
  };

  const editAgent = (agent) => {
    setEditingAgent({
      ...agent,
      codeproject: agent.codeproject.split(','), // Преобразование строки обратно в массив
      articlesids: agent.articlesids.split(','), // Преобразование строки обратно в массив
    });
  };

  const updateAgent = async () => {
    try {
      await axios.put(`/api/agent/${editingAgent.id}`, {
        ...editingAgent,
        codeproject: editingAgent.codeproject.join(','), // Преобразование массива в строку
        articlesids: editingAgent.articlesids.join(','), // Преобразование массива в строку
      });
      setEditingAgent(null);
      fetchAgents(); 
    } catch (error) {
      console.error('Ошибка обновления контрагента:', error);
    }
  };

  const handleCheckboxChange = (type, id, checked) => {
    if (type === 'project') {
      const updatedProjects = checked
        ? [...newAgent.codeproject, id]
        : newAgent.codeproject.filter((projectId) => projectId !== id);
      setNewAgent({ ...newAgent, codeproject: updatedProjects });
    } else if (type === 'article') {
      const updatedArticles = checked
        ? [...newAgent.articlesids, id]
        : newAgent.articlesids.filter((articleId) => articleId !== id);
      setNewAgent({ ...newAgent, articlesids: updatedArticles });
    }
  };

  return (
    <div className="container">
      <h2>Редактирование Контрагенты</h2>

      {/* Форма для добавления нового контрагента */}
      <div className="form-group">
        <h3>Добавить нового контрагента</h3>
        <input
          type="text"
          className="form-control"
          placeholder="Наименование"
          value={newAgent.name}
          onChange={(e) => setNewAgent({ ...newAgent, name: e.target.value })}
        />
        <textarea
          className="form-control"
          placeholder="Описание"
          value={newAgent.description}
          onChange={(e) => setNewAgent({ ...newAgent, description: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Роль"
          value={newAgent.role}
          onChange={(e) => setNewAgent({ ...newAgent, role: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Наименование map"
          value={newAgent.namemap}
          onChange={(e) => setNewAgent({ ...newAgent, namemap: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Логин map"
          value={newAgent.loginmap}
          onChange={(e) => setNewAgent({ ...newAgent, loginmap: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Логин"
          value={newAgent.login}
          onChange={(e) => setNewAgent({ ...newAgent, login: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Тип оплаты"
          value={newAgent.typepay}
          onChange={(e) => setNewAgent({ ...newAgent, typepay: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ"
          value={newAgent.kf}
          onChange={(e) => setNewAgent({ ...newAgent, kf: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ1"
          value={newAgent.kf1}
          onChange={(e) => setNewAgent({ ...newAgent, kf1: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ2"
          value={newAgent.kf2}
          onChange={(e) => setNewAgent({ ...newAgent, kf2: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ3"
          value={newAgent.kf3}
          onChange={(e) => setNewAgent({ ...newAgent, kf3: e.target.value })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="КФ4"
          value={newAgent.kf4}
          onChange={(e) => setNewAgent({ ...newAgent, kf4: e.target.value })}
        />

        {/* Чекбоксы для выбора проектов */}
        <h4>Выберите проекты</h4>
        {projects && projects.length > 0 ? (
          projects.map((project) => (
            <div key={project.id}>
              <input
                type="checkbox"
                checked={newAgent.codeproject.includes(project.id.toString())}
                onChange={(e) =>
                  handleCheckboxChange('project', project.id.toString(), e.target.checked)
                }
              />
              <label>{project.name}</label>
            </div>
          ))
        ) : (
          <div>Нет доступных проектов</div>
        )}

        {/* Чекбоксы для выбора статей расхода */}
        <h4>Выберите статьи расхода</h4>
        {articles && articles.length > 0 ? (
          articles.map((article) => (
            <div key={article.id}>
              <input
                type="checkbox"
                checked={newAgent.articlesids.includes(article.id.toString())}
                onChange={(e) =>
                  handleCheckboxChange('article', article.id.toString(), e.target.checked)
                }
              />
              <label>{article.name}</label>
            </div>
          ))
        ) : (
          <div>Нет доступных статей расхода</div>
        )}

        <button className="btn btn-primary" onClick={addAgent}>Добавить контрагента</button>
      </div>

      {/* Список контрагентов */}
      <div>
        <h3>Список контрагентов</h3>
        <ul>
          {agents && agents.length > 0 ? (
            agents.map((agent) => (
              <li key={agent.id}>
                {agent.name}
                <button onClick={() => editAgent(agent)}>Редактировать</button>
                <button onClick={() => deleteAgent(agent.id)}>Удалить</button>
              </li>
            ))
          ) : (
            <li>Нет доступных контрагентов</li>
          )}
          
        </ul>
      </div>

      {/* Форма для редактирования контрагента */}
      {editingAgent && (
        <div className="form-group">
          <h3>Редактировать контрагента</h3>
          <input
            type="text"
            className="form-control"
            value={editingAgent.name}
            onChange={(e) => setEditingAgent({ ...editingAgent, name: e.target.value })}
          />
          <textarea
            className="form-control"
            value={editingAgent.description}
            onChange={(e) => setEditingAgent({ ...editingAgent, description: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.role}
            onChange={(e) => setEditingAgent({ ...editingAgent, role: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.namemap}
            onChange={(e) => setEditingAgent({ ...editingAgent, namemap: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.loginmap}
            onChange={(e) => setEditingAgent({ ...editingAgent, loginmap: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.login}
            onChange={(e) => setEditingAgent({ ...editingAgent, login: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.typepay}
            onChange={(e) => setEditingAgent({ ...editingAgent, typepay: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.kf}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.kf1}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf1: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.kf2}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf2: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.kf3}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf3: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            value={editingAgent.kf4}
            onChange={(e) => setEditingAgent({ ...editingAgent, kf4: e.target.value })}
          />

          {/* Чекбоксы для выбора проектов при редактировании */}
          <h4>Выберите проекты</h4>
          {projects && projects.length > 0 ? (
            projects.map((project) => (
              <div key={project.id}>
                <input
                  type="checkbox"
                  checked={editingAgent.codeproject.includes(project.id.toString())}
                  onChange={(e) =>
                    handleCheckboxChange('project', project.id.toString(), e.target.checked)
                  }
                />
                <label>{project.name}</label>
              </div>
            ))
          ) : (
            <div>Нет доступных проектов</div>
          )}

          {/* Чекбоксы для выбора статей расхода при редактировании */}
          <h4>Выберите статьи расхода</h4>
          {articles && articles.length > 0 ? (
            articles.map((article) => (
              <div key={article.id}>
                <input
                  type="checkbox"
                  checked={editingAgent.articlesids.includes(article.id.toString())}
                  onChange={(e) =>
                    handleCheckboxChange('article', article.id.toString(), e.target.checked)
                  }
                />
                <label>{article.name}</label>
              </div>
            ))
          ) : (
            <div>Нет доступных статей расхода</div>
          )}

          <button className="btn btn-primary" onClick={updateAgent}>Обновить контрагента</button>
          <button onClick={() => setEditingAgent(null)}>Отмена</button>
        </div>
      )}
    </div>
  );
};

export default AgentEditor;
